@import "./src/assets/scss/prepends.scss";


.home {
  pointer-events: none;
  &__ttl-wrapper {
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translate(-52%, -56%);
    width: calc(1100/1920 * 100vw);
    z-index: 200;
    @include for-sp(){
      width: calc((750 - 60)/ 750 * 100%);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -60%);
    }
  }
  &__ttl {
    line-height: 0;
    transition: all 700ms cubic-bezier(.4,.64,.68,1);
    // transform: rotateX(75deg) rotateY(10deg) rotateZ(-9deg);
    clip-path: polygon(0% 90%, 100% 90%, 100% 90%, 0% 90%);
    transition: clip-path 900ms cubic-bezier(1.000, 0.185, 0.210, 0.955);
    img {
      width: 100%;
      height: auto;
    }
  }
  &__txt {
    color: $white;
    @include font-en-ttl;
    font-size: calc(25/1920 * 100vw);
    position: absolute;
    top: calc(180/486 * 100%);
    left: calc(670/1100 * 100%);
    clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
    transition: clip-path 900ms cubic-bezier(1.000, 0.185, 0.210, 0.955);
    width: max-content;
    @include for-sp(){
      font-size: calc(20/750 * 100vw);
      top: calc(100/750 * 100vw);
      left: calc(410/750 * 100vw);
    }
  }
  &__news {
    position: absolute;
    z-index: 200;
    bottom: calc(60/1920 * 100vw);
    left: calc(60/1920 * 100vw);
    width: max-content;
    @include for-sp(){
      left: auto;
      right: calc(30/750 * 100vw);
      bottom: calc(30/750 * 100vw);
    }
    p {
      @include font-en-ttl;
      font-size: calc(20/1920 * 100vw);
      color: $white;
      line-height: 1;
      background-color: $black;
      padding: calc(3/1920 * 100vw) calc(5/1920 * 100vw);
      @include for-sp(){
        font-size: calc(20/750 * 100vw);  
      }
    }
  }
  &__img{
    ul {
      li {
        position: absolute;
        overflow: hidden;
        .home__img-box {
          width: 100%;
          height: 100%;
          transition: transform 700ms cubic-bezier(.4,.64,.68,1);
          .home__img-subbox {
            width: 100%;
            height: 100%;
            clip-path: polygon(10% 100%, 90% 100%, 90% 100%, 10% 100%);
            transition: clip-path 900ms cubic-bezier(1.000, 0.185, 0.210, 0.955);
          }
        }

        /* 1 */
        &:nth-child(10n+1){
          width: calc(500/1920 * 100vw);
          height: calc(500/1920 * 100vw);
          top: calc(0/1920 * 100vw);
          animation: moveSpeed1 40s linear 0s infinite normal;
          @include for-sp(){
            width: calc(300/750 * 100vw);
            height: calc(300/750 * 100vw);
            top: calc(80/750 * 100vw);
          }
        }
        &:nth-child(1){ 
          left: calc(164/1920 * 100vw);
          z-index: 90;
        }
        &:nth-child(11){
          left: calc(164/1920 * 100vw + 100vw);
          z-index: 90;
        }
        
        /* 2 */
        &:nth-child(10n+2){
          width: calc(500/1920 * 100vw);
          height: calc(500/1920 * 100vw);
          bottom: calc(-90/1920 * 100vw);
          animation: moveSpeed1 40s linear 0s infinite normal;
          @include for-sp(){
            width: calc(300/750 * 100vw);
            height: calc(300/750 * 100vw);
            bottom: calc(100/750 * 100vw);
          }
        }
        &:nth-child(2){ 
          left: calc(1400/1920 * 100vw);
          z-index: 80;
        }
        &:nth-child(12){
          left: calc(1400/1920 * 100vw + 100vw);
          z-index: 80;
        }

        /* 3 */
        &:nth-child(10n+3){
          width: calc(500/1920 * 100vw);
          height: calc(500/1920 * 100vw);
          top: calc(-80/1920 * 100vw);
          animation: moveSpeed1 30s linear 0s infinite normal;
          @include for-sp(){
            width: calc(300/750 * 100vw);
            height: calc(300/750 * 100vw);
          }
        }
        &:nth-child(3){ 
          left: calc(1420/1920 * 100vw);
          z-index: 100;
        }
        &:nth-child(13){
          left: calc(1420/1920 * 100vw + 100vw);
          z-index: 100;
        }

        /* 4 */
        &:nth-child(10n+4){
          width: calc(350/1920 * 100vw);
          height: calc(350/1920 * 100vw);
          bottom: calc(50/1920 * 100vw);
          animation: moveSpeed1 80s linear 0s infinite normal;
          @include for-sp(){
            width: calc(200/750 * 100vw);
            height: calc(200/750 * 100vw);
            bottom: calc(300/750 * 100vw);
          }
        }
        &:nth-child(4){ 
          left: calc(30/1920 * 100vw);
          z-index: 50;
        }
        &:nth-child(14){
          left: calc(30/1920 * 100vw + 100vw);
          z-index: 50;
        }

        /* 5 */
        &:nth-child(10n+5){
          width: calc(350/1920 * 100vw);
          height: calc(350/1920 * 100vw);
          bottom: calc(-20/1920 * 100vw);
          animation: moveSpeed1 40s linear 0s infinite normal;
          @include for-sp(){
            width: calc(200/750 * 100vw);
            height: calc(200/750 * 100vw);
            bottom: calc(-20/750 * 100vw);
          }
        }
        &:nth-child(5){ 
          left: calc(960/1920 * 100vw);
          z-index: 70;
        }
        &:nth-child(15){
          left: calc(960/1920 * 100vw + 100vw);
          z-index: 70;
        }

        /* 6 */
        &:nth-child(10n+6){
          width: calc(350/1920 * 100vw);
          height: calc(350/1920 * 100vw);
          top: calc(30/1920 * 100vw);
          animation: moveSpeed1 40s linear 0s infinite normal;
          @include for-sp(){
            width: calc(200/750 * 100vw);
            height: calc(200/750 * 100vw);
            top: calc(40/750 * 100vw);
          }
        }
        &:nth-child(6){ 
          left: calc(760/1920 * 100vw);
          z-index: 60;
        }
        &:nth-child(16){
          left: calc(760/1920 * 100vw + 100vw);
          z-index: 60;
        }

        /* 7 */
        &:nth-child(10n+7){
          width: calc(300/1920 * 100vw);
          height: calc(300/1920 * 100vw);
          bottom: calc(200/1920 * 100vw);
          animation: moveSpeed1 100s linear 0s infinite normal;
          @include for-sp(){
            width: calc(150/750 * 100vw);
            height: calc(150/750 * 100vw);
            bottom: calc(200/750 * 100vw);
          }
        }
        &:nth-child(7){ 
          left: calc(530/1920 * 100vw);
          z-index: 30;
        }
        &:nth-child(17){
          left: calc(530/1920 * 100vw + 100vw);
          z-index: 30;
        }

        /* 8 */
        &:nth-child(10n+8){
          width: calc(300/1920 * 100vw);
          height: calc(300/1920 * 100vw);
          top: calc(160/1920 * 100vw);
          animation: moveSpeed1 70s linear 0s infinite normal;
          @include for-sp(){
            width: calc(200/750 * 100vw);
            height: calc(200/750 * 100vw);
            top: calc(200/750 * 100vw);
          }
        }
        &:nth-child(8){ 
          left: calc(1100/1920 * 100vw);
          z-index: 40;
          @include for-sp(){
            left: calc(550/750 * 100vw);
          }
        }
        &:nth-child(18){
          left: calc(1100/1920 * 100vw + 100vw);
          z-index: 40;
          @include for-sp(){
            left: calc(550/750 * 100vw);
          }
        }

        /* 9 */
        &:nth-child(10n+9){
          width: calc(300/1920 * 100vw);
          height: calc(300/1920 * 100vw);
          bottom: calc(140/1920 * 100vw);
          animation: moveSpeed1 70s linear 0s infinite normal;
          @include for-sp(){
            width: calc(200/750 * 100vw);
            height: calc(200/750 * 100vw);
          }
        }
        &:nth-child(9){ 
          left: calc(1600/1920 * 100vw);
          z-index: 20;
          @include for-sp(){
            left: calc(100/750 * 100vw);
          }
        }
        &:nth-child(19){
          left: calc(1600/1920 * 100vw + 100vw);
          z-index: 20;
          @include for-sp(){
            left: calc(100/750 * 100vw + 100vw);
          }
        }

        /* 10 */
        &:nth-child(10n+10){
          width: calc(250/1920 * 100vw);
          height: calc(250/1920 * 100vw);
          top: calc(260/1920 * 100vw);
          animation: moveSpeed1 80s linear 0s infinite normal;
          @include for-sp(){
            width: calc(200/750 * 100vw);
            height: calc(200/750 * 100vw);
          }
        }
        &:nth-child(10){ 
          left: calc(1650/1920 * 100vw);
          z-index: 10;
          @include for-sp(){
            left: calc(400/750 * 100vw);
          }
        }
        &:nth-child(20){
          left: calc(1650/1920 * 100vw + 100vw);
          z-index: 10;
          @include for-sp(){
            left: calc(400/750 * 100vw + 100vw);

          }
        }
      }
      a {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        // filter: grayscale(100%);
        -webkit-backface-visibility:hidden;
        backface-visibility:hidden;
        transition: all .6s cubic-bezier(.4,.64,.68,1);
        // height: 0;
        overflow: hidden;
        &::after {
          // content: '';
          display: block;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.8);
          position: absolute;
          top: 0;
          left: 0;
          transition: all .6s cubic-bezier(.4,.64,.68,1);
        }
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          transition: all .6s cubic-bezier(.4,.64,.68,1);
          transform: scale(1.2);
        }
      }
    }
  }
  &__bg {
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      background-size:cover;
      background-position: 50% 50%;
      filter: grayscale(100%);
      opacity: 0;
      animation: moveBg 20s linear 0s infinite normal;
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
      }
    }
    @keyframes moveBg {
      0% {
        // background-position: 50% 40%
        transform: scale(1);
      }
      100% {
        // background-position: 50% 60%
        transform: scale(1.2)
      }
    }
  &__btn-list {
  }
  &__btn {
    position: absolute;
    &--about {
      top: 50%;
      transform: translate(0, -50%);
      left: calc(60/1920 * 100vw);
    }
    &--contact {
      top: 50%;
      transform: translate(0, -50%);
      right: calc(60/1920 * 100vw);
    }
  }
}



@keyframes moveSpeed1 {
  0% {
    transform: translate(0, 0) scale(calc(100/90));
  }
  100% {
    transform: translate(calc(-100vw), 0) scale(calc(100/90));
  }
}


.home-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $red;
  z-index: 9999;
  @include for-sp(){
    width: 100%;
    height: 100%;
  }
  &__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -65%);
  }
  .num {
    @include font-en-ttl;
    font-size: calc(50/1920 * 100vw);
    color: $white;
    text-align: center;
    line-height: 1;
    padding-bottom: calc(20/1920 * 100vw);
    @include for-sp(){
      font-size: calc(40/750 * 100vw);
    }
  }
  .bar{
    width: calc(200/1920 * 100vw);
    height: calc(5/1920 * 100vw);
    background-color: rgba(255, 255, 255, 0.3);
    position: relative;
    @include for-sp(){
      width: calc(200/750 * 100vw);
    }
    span {
      position: absolute;
      top: 0;
      left: 0;
      // width: 100%;
      background-color: $white;
      height: 100%;
      transition: all ease 300ms;
    }
  }
}

.is--pointer-event-on {
  pointer-events: auto;
}

.is--loaded {
  --img-delay: 900ms;
  .home-loading {
    transition: all 600ms cubic-bezier(.4,.64,.68,1); 
    transform: translate(0, -100%);
    .bar {
      display: none;
    }
  }
  .home__ttl {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  .home__txt {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  .home__img{
    ul {
      li {
        .home__img-subbox {
          clip-path: polygon(10% 10%, 90% 10%, 90% 90%, 10% 90%);
        }
        a {
          img {
            transition: all 600ms cubic-bezier(.4,.64,.68,1);
            transition-delay: 1500ms;
            transform: scale(1);
          }
        }
        &:nth-child(1), &:nth-child(10) {
          .home__img-subbox {
            transition-delay: calc(200ms + var(--img-delay));
          }
        }
        &:nth-child(2), &:nth-child(11) {
          .home__img-subbox {
            transition-delay: calc(100ms + var(--img-delay));
          }
        }
        &:nth-child(3), &:nth-child(12) {
          .home__img-subbox {
            transition-delay: calc(200ms + var(--img-delay));
          }
        }
        &:nth-child(4), &:nth-child(13) {
          .home__img-subbox {
            transition-delay: calc(200ms + var(--img-delay));
          }
        }
        &:nth-child(5), &:nth-child(14) {
          .home__img-subbox {
            transition-delay: calc(100ms + var(--img-delay));
          }
        }
        &:nth-child(6), &:nth-child(15) {
          .home__img-subbox {
            transition-delay: calc(200ms + var(--img-delay));
          }
        }
        &:nth-child(7), &:nth-child(16) {
          .home__img-subbox {
            transition-delay: calc(100ms + var(--img-delay));
          }
        }
        &:nth-child(8), &:nth-child(17) {
          .home__img-subbox {
            transition-delay: calc(100ms + var(--img-delay));
          }
        }
        &:nth-child(9), &:nth-child(18) {
          .home__img-subbox {
            transition-delay: calc(250ms + var(--img-delay));
          }
        }
        &:nth-child(10), &:nth-child(19) {
          .home__img-subbox {
            transition-delay: calc(250ms + var(--img-delay));
          }
        }
      }
    }
  }
}

/* hover */
.no-touchevents {
  .home__img-box:hover {
    z-index: 20;
    transform: scale(1.15);
    .home__img-subbox {
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
    a {
      &::after {
        display: none;
      }
    }
    img {
      transform: scale(0.95);
    }
  }
}


.v-enter-active{
  transition: none; //entering top page is no transition.
}.v-leave-active {
  transition: all 700ms cubic-bezier(.4,.64,.68,1);
}
.v-enter-from {
  opacity: 0;
} 
.v-enter-to {
} 

.v-leave-from {
}
.v-leave-to {
  opacity: 0;
}
