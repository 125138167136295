@import "./src/assets/scss/prepends.scss";

.v-enter-active, .v-leave-active {
  transition: all 700ms cubic-bezier(.4,.64,.68,1);
}
.v-enter-from {
  opacity: 0;
} 
.v-enter-to {
} 

.v-leave-from {
}
.v-leave-to {
  opacity: 0;
}
