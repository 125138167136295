@import "./src/assets/scss/prepends.scss";

.mouse-cursor {
  width: 5px;
  height: 5px;
  border-radius: 50%;;
  background-color: $white;
  position: fixed;
  z-index: 999;
  pointer-events: none;
  @include for-sp(){
    display: none;
  }
}
.mouse-stalker {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1px;
  height: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  pointer-events: none;
  mix-blend-mode: exclusion;
  @include for-sp(){
    display: none;
  }
  &.is--hover {
    mix-blend-mode: normal;
    .circle {
      width: calc(80/1920 * 100vw);
      height: calc(80/1920 * 100vw);
      // background-color: $white;
      // @include gradient-color;
      background-color: $red;
      border: 1px solid transparent;
      opacity: 1;
    }
    .txt {
      opacity: 1;
    }
    i {
      opacity: 1;
    }
  }
  .circle {
    width: calc(40/1920 * 100vw);
    height: calc(40/1920 * 100vw);
    border-radius: 50%;
    background-color: transparent;
    box-sizing: border-box;
    border: 1px solid $white;
    flex-shrink: 0;
    transition: all ease 200ms;
  }
  .txt {
    font-size: calc(20/1920 * 100vw);
    color: white;
    @include font-en-ttl;
    line-height: 1;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  i {
    display: block;
    width: calc(30/1920 * 100vw);
    height: calc(2/1920 * 100vw);
    background-color: $white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    &::after {
      position: absolute;
      content: '';
      display: block;
      right: calc(-3/1920 * 100vw);
      bottom: 0;
      // transform: translate(0, -50%);
      width: calc(10/1920 * 100vw);
      height: calc(10/1920 * 100vw);
      background-color: $white;
      clip-path: polygon(0% 0%, 100% 100%, 100% 100%, 0 100%);
    }
  }
}
