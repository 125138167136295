@charset "UTF-8";

/* ==========================================================================
   base
   ======================================================================= */

html {
  @include font-ja;
  font-size: 62.5%;
  overflow-x: hidden;
  @include for-sp(){
    width: 100%;
    height: 100%;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  cursor: none;
  @include for-sp(){
    width: 100%;
    height: 100%;
  }
}
body a:hover{
  cursor: none;
}

button,
input,
optgroup,
select,
textarea {
  @include font-ja;
}


/* ==========================================================================
   util
   ======================================================================= */
.u-pc-only {
  display: block!important;
  @include for-sp{
    display: none!important;
  }
}
.u-sp-only {
  display: none!important;
  @include for-sp{
    display: block!important;
  }
}

.u-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #242427 8%, #343438 18%, #242427 33%);
  background-size: 200% 100%;
  animation: loadAnimation 1100ms linear 0s infinite normal;
  z-index: -1;
}
@keyframes loadAnimation {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}