@import "./src/assets/scss/prepends.scss";

.gnav {
  &__logo {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(140/1920 * 100vw);
    height: calc(140/1920 * 100vw);
    z-index: 200;
    mix-blend-mode: exclusion;
    @include for-sp(){
      width: calc(100/750 * 100vw);
      height: calc(100/750 * 100vw);
    }
    a {
      width: 100%;
      height :100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: calc(30/1920 * 100vw);
        @include for-sp(){
          width: calc(40/750 * 100vw);
        }
      }
    }
  }
  &-menu {
    &__btn {
      position: fixed;
      top: 0;
      right: 0;
      width: calc(140/1920 * 100vw);
      height: calc(140/1920 * 100vw);
      mix-blend-mode: exclusion;
      z-index: 301;
      @include for-sp(){
        top: auto;
        bottom: 0;
        left: 0;
        right: auto;
        width: calc(100/750 * 100vw);
        height: calc(100/750 * 100vw);
        mix-blend-mode: normal;
      }
      a {
        display: block;
         width: 100%;
         height: 100%;
         transition: all .6s cubic-bezier(.4,.64,.68,1);
         @include for-sp(){
           background-color: $white;
         }
      }
      &.is--open {
        .gnav-menu__bar--1-1{
          transform: rotate(-23deg);
        }
        .gnav-menu__bar--1-2{
          transform: rotate(23deg);
        }
        .gnav-menu__bar--2-1{
          transform: rotate(23deg);
        }
        .gnav-menu__bar--2-2{
          transform: rotate(-23deg);
        }
      }
    }
    &__bar {
      position: absolute;
      width: calc(30/1920 * 100vw);
      height: 4px;
      background-color: $white;
      transition: all .6s cubic-bezier(.4,.64,.68,1);
      @include for-sp(){
        width: calc(30/750 * 100vw);
        height: calc(4/750 * 100vw);
        background-color: $black;
      }
    }
    &__bar--1-1 {
      top: calc(63/1920 * 100vw);
      right: calc(42/1920 * 100vw);
      @include for-sp(){
        right: calc(25/750 * 100vw);
        top: calc(41/750 * 100vw);
      }
    }
    &__bar--1-2 {
      top: calc(63/1920 * 100vw);
      right: calc(70/1920 * 100vw);
      @include for-sp(){
        right: calc(48/750 * 100vw);
        top: calc(41/750 * 100vw);
      }
    }
    &__bar--2-1 {
      top: calc(76/1920 * 100vw);
      right: calc(42/1920 * 100vw);
      @include for-sp(){
        right: calc(25/750 * 100vw);
        top: calc(52/750 * 100vw);
      }
    }
    &__bar--2-2 {
      top: calc(76/1920 * 100vw);
      right: calc(70/1920 * 100vw);
      @include for-sp(){
        right: calc(48/750 * 100vw);
        top: calc(52/750 * 100vw);
      }
    }
  }
  &-list {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 300;
    @include for-sp(){
      width: 100%;
      height: 100vh;
    }
    ul {
      display: flex;
      height: 100%;
      @include for-sp(){
        box-sizing: border-box;
        flex-direction: column;
        padding: calc(200/750 * 100vw) 0 calc(400/750 * 100vw);
      }
      .is--current {
        i {
          display: block;
          height: calc(50/1920 * 100vw);
          width: calc(100%);
          @include gradient-color;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
          z-index: -1;
          transition: all 700ms cubic-bezier(.4,.64,.68,1);
        }
      }
      li {
        width: 25%;
        height: 100%;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: calc(140/1920 * 100vw) 0;
        overflow: hidden; 
        @include for-sp(){
          width: 100%;
          height: 25%;
          padding: 0;
          display: block;
        }
        a {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          display: block;
          position: relative;
          transition: all 700ms cubic-bezier(.4,.64,.68,1);
          @include for-sp(){
            padding-right: calc(50/750 * 100vw);
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: flex-end;
          }
          &:hover {
            background-color: $white;
            .txt1 {
              color: $black;
              letter-spacing: 0.15em;
            }
            .txt2 {
              -webkit-text-stroke-color: rgba(0, 0, 0, 0.3);
              animation: txt-move 20s linear 0s infinite normal;
            }
            .line {
              background-color: $black;
            }
          }
        }
        .txt1 {
          @include font-en-ttl;
          position: absolute;
          font-size: calc(120/1920 * 100vw);
          color: $white;
          padding-left: calc(30/1920 * 100vw);
          bottom: calc(-120/1920 * 100vw);
          left: calc(180/1920 * 100vw);
          transform-origin: top left;
          transform: rotate(-90deg);
          display: block;
          line-height: 1;
          transition: all 600ms cubic-bezier(.4,.64,.68,1);
          @include for-sp(){
            left: auto;
            bottom: auto;
            transform: rotate(0);
            position: relative;
            font-size: calc(100/750 * 100vw);
          }
          .clip {
            display: block;
            line-height: 1;
            // transition: clip-path 850ms cubic-bezier(1.000, 0.185, 0.210, 0.955);
            transition: clip-path 800ms cubic-bezier(0.155, 0.1, 0.135, 1.01);
          }
        }
        .txt2 {
          @include font-en-ttl;
          font-size: calc(220/1920 * 100vw);
          color: $white;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: rgba(255, 255, 255, 0.3);
          color: transparent;
          // transform: rotate(-90deg);
          display: block;
          @include for-sp(){
            display: none;
            font-size: calc(150/750 * 100vw);
            line-height: 1;
            text-align: right;
            -webkit-text-stroke-color: rgba(255, 255, 255, 0.2);  
          }
        }
        .line {
          width: calc(4/1920 * 100vw);
          height: calc(65/1920 * 100vw);
          background-color: $white;
          position: absolute;
          top: 1px;
          left: 50%;
          margin-left: calc(-2/1920 * 100vw);
        }
        
      }
    }
  }

  .horizontal {
    position: absolute;
    left: 0;
    width: 100vw;
    height: 1px;
    background-color: $gray;
    z-index: 301;
    @include for-sp(){
      display: none;
    }
    &--1 {
      top: calc(140/1920 * 100vw);
    }
    &--2 {
      bottom: calc(140/1920 * 100vw);
    }
  }
  .vertical {
    position: absolute;
    top: 0;
    width: 1px;
    height: 100vh;
    background-color: $gray;
    z-index: 305;
    @include for-sp(){
      display: none;
    }
    &--1 {
      left: 25vw;
    }
    &--2 {
      left: 50vw;
    }
    &--3 {
      left: 75vw;
    }
  }
}

@keyframes txt-move {
  0% {
    transform: translate(0, 0);
  }
  100%{
    transform: translate(100%, 0);
  }
}


/* animation */
.v-enter-active,
.v-leave-active {
  transition: all 700ms cubic-bezier(.4,.64,.68,1);
}
.horizontal,
.vertical {
  transition: all 1000ms cubic-bezier(.4,.64,.68,1);
}
.txt1 {
  transition: all 1000ms cubic-bezier(.4,.64,.68,1);
}

.v-enter-from {
  opacity: 0;
  transform: translate(0, -10vh);
  ul .is--current {
    i {
      clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
    }
  }
  .txt1 {
    .clip {
      clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
    }
  }
  .horizontal {
    width: 0;
  }
  .vertical {
    height: 0
  }
}
.v-enter-to {
  opacity: 1;
  transform: translate(0, 0);
  ul .is--current {
    i {
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
  }
  .txt1 {
    .clip {
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
  }
  .horizontal {
    width: 100vw;
  }
  .vertical { 
    height: 100vh;
  }
}


.v-leave-from {
  opacity: 1;
}
.v-leave-to {
  opacity: 0;
  transform: translate(0, -10vh);
}


/* hover */
.no-touchevents {
  .gnav {
    &-menu {
      &__btn {
        a:hover {
          background-color: $white;
          .gnav-menu__bar {
            background-color: $black;
          }
        }
      }
    }
    &-list {
      a:hover {
        background-color: $white;
        .txt1 {
          color: $black;
          letter-spacing: 0.15em;
        }
        .txt2 {
          -webkit-text-stroke-color: rgba(0, 0, 0, 0.3);
          animation: txt-move 20s linear 0s infinite normal;
        }
        .line {
          background-color: $black;
        }
      }
    }
  }
}

