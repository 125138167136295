@import "./src/assets/scss/prepends.scss";

.btn {
  width: calc(160/1920 * 100vw);
  height: calc(100/1920 * 100vw);
  position: relative;
  @include for-sp(){
    width: calc(70/750 * 100vw);
    height: calc(70/750 * 100vw);
  }
  &__txt {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    @include font-en-ttl;
    font-size: calc(18/1920 * 100vw);
    color: $white;
    line-height: 1;
    z-index: 3;
    display: block;
    @include for-sp(){
      // font-size: calc(20/750 * 100vw);
      display: none;
    }
  }
  &__circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100/1920 * 100vw);
    height: calc(100/1920 * 100vw);
    // border: calc(8/1920 * 100vw) solid $white;
    box-sizing: border-box;
    opacity: 0.2;
    border-radius: 100%;
    transition: all 600ms cubic-bezier(.4,.64,.68,1);
    transition-delay: 200ms;
    @include for-sp(){
      opacity: 1;
      width: calc(70/750 * 100vw);
      height: calc(70/750 * 100vw);
      border: 1px solid $white;
      background-color: transparent;
    }
  }
  &__svg-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100/1920 * 100vw);
    height: calc(100/1920 * 100vw);
    // border: calc(8/1920 * 100vw) solid $white;
    box-sizing: border-box;
    opacity: 0.2;
    border-radius: 100%;
    transition: all 600ms cubic-bezier(.4,.64,.68,1);
    fill: transparent;
    stroke: $white;
    stroke-width: calc(8/1920 * 100vw);
    @include for-sp(){
      display: none;
    }
  }
  &__line {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: calc(100/1920 * 100vw);
    height: calc(4/1920 * 100vw);
    background-color: $white;
    transition: all 600ms cubic-bezier(.4,.64,.68,1);
    display: block;
    @include for-sp(){
      width: calc(30/750 * 100vw);
      height: calc(2/750 * 100vw);
      transform: translate(-50%, -50%);
    }
    &::after {
      @include for-sp(){
        content: '';
        display: block;
        width: calc(10/750 * 100vw);
        height: calc(10/750 * 100vw);
        background-color: $white;
        position: absolute;
        bottom: 0;
      }
    }
  }
  &--left {
    .btn__txt {
      left: 0;
    }
    .btn__line {
      left: 50%;
      &::after {
        clip-path: polygon(0% 100%, 100% 0%, 100% 100%, 0 100%);
        left: 0;
      }
    }
    .btn__svg-circle {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
  &--right {
    .btn__txt {
      right: 0;
    }
    .btn__line {
      right: 50%;
      @include for-sp(){
        left: 50%;
      }
      &::after {
        @include for-sp(){
          clip-path: polygon(0% 0%, 100% 100%, 100% 100%, 0 100%);
          right: 0;
        }
      }
    }
  }
  &--simple {
    .btn__txt {
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  a{
    display: block;
    width: 100%;
    height: 100%;
    &:hover {

    }
  }

@keyframes animation-svg-circle {
  0% {
    stroke-dasharray: 0 2000;
  }
  100% {
    stroke-dasharray: 2000 2000;
  }
}    
}


/* hover */
.no-touchevents {
  a {
    &:hover {
      .btn__txt {
        color: $black;
        letter-spacing: 0.5em;
        width: 100%;
        text-align: center;
        transition: all .6s cubic-bezier(.4,.64,.68,1);
      }
      .btn__circle {
        width: calc(200/1920 * 100vw);
        height: calc(200/1920 * 100vw);
        background-color: $white;
        border: 0;
        opacity: 1;
      }
      .btn__svg-circle {
        animation: animation-svg-circle 2s cubic-bezier(.4,.64,.68,1) 0s normal;
      }
      .btn__line {
        background-color: $black;
        width: calc(0/1920 * 100vw);
      }
    }
  }
  
}
